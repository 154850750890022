/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";

import React from "react";
import { Link } from "react-router-dom";
import Footer from ":/components/Footer";
import Header from ":/components/Header";

import { responsiveSize } from ":/lib/CssHelpers";

import embark_image from ":/assets/img/embark.png";
import possible_world_portal from ":/assets/img/possible-world-portal.png";

export default function () {
  const [embarkCornerRadius, setEmbarkCornerRadius] = React.useState(55);
  const [embarkHolderWidth, setEmbarkHolderWidth] = React.useState(110);
  const [heroOpacity, setHeroOpacity] = React.useState(1);

  React.useLayoutEffect(() => {
    const onScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollPos = window.scrollY + window.innerHeight;
      const min = windowHeight * 1.5;
      const max = windowHeight * 2;
      if (scrollPos < min) {
        setHeroOpacity(1);
      } else if (scrollPos > max) {
        setHeroOpacity(0);
      } else {
        setHeroOpacity(1 - (scrollPos - min) / (max - min));
      }
      if (true) {
        const pos = scrollPos - windowHeight;
        const relPos = pos / windowHeight;
        const n = Math.max(
          0,
          1 - (scrollPos - windowHeight) / (windowHeight * 1.5 - windowHeight)
        );
        setEmbarkCornerRadius((110 + 100 * relPos) / 2);
        setEmbarkHolderWidth(110 + 100 * relPos);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <div css={CSS}>
      <div
        className="fixed-header"
      >
        <div className="fixed-header-bg" style={{ opacity: 1 - heroOpacity }} />
        <Header />
      </div>
      <div className="fixed-hero" style={{ opacity: heroOpacity }}>
        <h1>a protocol and network for p2p digital agreements</h1>
        <div>
          <a
            className="item button-holder"
            href={process.env.REACT_APP_DISCORD_INVITE}
          >
            <div className="button">Join Discord</div>
          </a>
        </div>
      </div>
      <div className="pre-embark" />
      <div className="embark-scroll">
        <div
          className="embark-image-holder"
          style={{
            width: `${embarkHolderWidth}vw`,
            marginLeft: `calc((${embarkHolderWidth}vw - 100vw) / -2)`,
            borderTopLeftRadius: `${embarkCornerRadius}vw`,
            borderTopRightRadius: `${embarkCornerRadius}vw`,
          }}
        >
          <img className="embark-image" src={possible_world_portal} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

const CSS = css`
  position: relative;
  margin: 0px auto;
  text-align: center;
  width: 100%;
  overflow: hidden;
  .fixed-hero {
    position: fixed;
    top: calc(30vh);
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  h1 {
    font-family: hw-cigars, serif;
    font-size: ${responsiveSize({
      min: "24px",
      max: "40px",
      min_device_width: "300px",
      max_device_width: "700px",
    })};
    line-height: 1.1em;
    font-weight: 400;
    max-width: calc(100vw - 40px);
    width: 1000px;
    display: inline-block;
  }
  .pre-embark {
    min-height: 75vw;
    height: calc(100vh - 174px - 32px);
  }
  .embark-scroll {
    /* height: 10000px; */
    .embark-image-holder {
      overflow: hidden;
      text-align: center;
    }
    .embark-image {
      width: 100vw;
      display: inline;
    }
  }
  .fixed-header {
    position: fixed;
    width: 100vw;
  }
  .fixed-header-bg {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    background-color: white;
  }
  .fixed-hero a.button-holder {
    display: inline-block;
    margin-top: 20px;
    padding: 0 4rem;
    height: 4rem;
    line-height: 3.8rem;
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.04em;
    background-color: #55b9ff;
    border: 0.1rem solid #55b9ff;
    border-radius: 7.4rem;
    box-shadow: 0 1.2rem 4rem rgb(0 0 0 / 10%);
  }
  body.dark-theme & {
    .fixed-header-bg {
      background-color: black;
    }
  }
`;
